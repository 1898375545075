import { createStore } from 'vuex';
import {  httpCore } from '../mixings/http.js'
import modules from './modules';
import ConfigMixing from '../mixings/ConfigMixing';



    const dbModule = {
        state: () => ({ 
            companies     : [],
        }),
        mutations: {
            setDbCompanies(state, companies){
                state.companies = companies;
            },
        }
    }

    const moduleSession = {
        state: () => ({ 
            loaded: false,
            lastRefresh     : null,
            logged          : false,
            allowFutureInvoice : false,
            companyAllowFutureInvoice : false,
            jwt             : null,
            version         : null,
            apiUrl          :null, 
            env             : null,
            ip              : null,
            ipData          :{
                city : null,
                state : null,
            },
            userName        : null,
            role            :  null,
            userId          : null,
            sessionId       : null,
            permissions     : [],
            myCompanies : [],
            company         : null,
            companyId : null,
            refreshToken    : null,
        }),
        mutations: {
            setIPCity(state, city){
                state.ipData.city = city;
            },
            setIPState(state, states){
                state.ipData.state = states;
            },
            setIP(state, ip){
                state.ip = ip;
            },
            setEnv(state, env){
                state.env = env;
            },
            setLoaded(state, loaded) {
                state.loaded = loaded
            },
            setApiUrl(state, apiUrl) {
                state.apiUrl = apiUrl
            },
            setVersion(state, version){
                state.version = version
            },
            setLogged(state, { logged }){
                state.logged = logged;
            },
            setLastRefresh(state){
                state.lastRefresh = new Date();
            },
            setLogout(state){

                httpCore.post(`/session/logout/?refreshToken=${state.refreshToken}`).then(function(){
                    console.log(`Session ended succesfully`);
                }).catch(function(){ console.error(`Error while track the session End`)})

                state.jwt = null;
                state.logged = false;
                state.sessionId = null;
                state.allowFutureInvoice = false;
                state.companyAllowFutureInvoice = false;
                state.company = null;
                state.refreshToken = null;
                state.companyId = null;
                state.userName = null;
                state.role = null;
                state.userId = null;
                state.refreshToken = null; 
                localStorage.removeItem('cache_config_pl');
                localStorage.removeItem('trp-login');
            },
            setLogin(state, { jwt, refreshToken, sessionId, permissions, role }){
                state.jwt = jwt;
                state.logged = true;
                state.role = role;
                state.permissions = permissions
                state.sessionId = sessionId;
                state.refreshToken = refreshToken;
                getUserInfo();
                persistSession();
                httpCore.post(`/session/ping/?refreshToken=`).then(function(){ console.log(`Ping succesfully`);}).catch(function(){ console.error(`Error while track the session End`)})
            },
            execRefresh(state, { jwt, refreshToken, sessionId }){
                state.jwt = jwt;
                state.logged = true;
                state.sessionId = sessionId;
                state.refreshToken = refreshToken;
                getUserInfo();
                persistSession();
            },
            setCompany(state, company){
                state.company = company;
            },
            setMyCompanies(state, myCompanies){
                state.myCompanies = myCompanies;
            },
            setAllowFutureInvoice(state, allowFutureInvoice){
                state.allowFutureInvoice = allowFutureInvoice;
            },
            setCompanyAllowFutureInvoice(state, companyAllowFutureInvoice){
                state.companyAllowFutureInvoice = companyAllowFutureInvoice;
            },

            setCompanyId(state, companyId){
                state.companyId = companyId;
            },
            setUserName(state, userName){
                state.userName = userName;
            },
            setUserId(state, userId){
                state.userId = userId;
            },
            setUserRole(state, role){
                state.role = role;
            },
            setJwt(state, jwt){
                state.jwt = jwt;
            },
            setRefreshToken(state, refreshToken){
                state.refreshToken = refreshToken;
            },
            setSessionId(state, sessionId){
                state.sessionId = sessionId;
            }
        }
    }


    const store = createStore({
      modules: {
          ...modules,
            db : dbModule,
            session : moduleSession
      },
      strict: process.env.NODE_ENV !== 'production',
    })

    function setConfig(data) {
        var user = data.user;
       
        if (user != undefined) {
            
            store.commit('setCompanyId', user.companyId);
            if(user.company)
                store.commit('setCompany', user.company);
            store.commit('setUserName', user.userName);
            store.commit('setUserId', user.userId);
            store.commit('setUserRole', user.role);
            //store.commit('setUserPermissions', data.userPermissions);
        }
        store.commit("setMyCompanies", data.myCompanies);
        if(data.db){
            if(data.db.companies)
                store.commit("setDbCompanies", data.db.companies);
        }

        store.commit('setLoaded', true);

    }

    function  loadConfig(){
        return new Promise( (resolve, reject) => {
            try {
                var cacheConfig = null;//localStorage.getItem('cache_config_pl');
                if (!cacheConfig || JSON.parse(cacheConfig).exp <= new Date().getTime()) {
                    console.log('cache_config not exist');
                    ConfigMixing.config().then(function(c){
                        setConfig(c.data);
                        var cache = {
                            exp: new Date().getTime() + (8 * 60 * 60 * 1000),
                            data : c.data
                        }
                        var cacheConfig = JSON.stringify(cache);
                        localStorage.setItem('cache_config_pl', cacheConfig)

                        
                        resolve(true);
                    }).catch(function(){
                        reject(false);
                    }) 
                } else {
                    console.log('cache_config exist!');
                    cacheConfig = JSON.parse(localStorage.getItem('cache_config_pl'));
                    setConfig(cacheConfig.data);
                    resolve(true);
                }


            } catch{
                reject(false);
            }
        });
    }

const getUserInfo = () => {
        localStorage.removeItem('cache_config');
        console.log(`Loading Config...`);
        loadConfig();
        ConfigMixing.config().then(function(c){
            console.log(`Config OK`);
            var user = c.data.user;
            //store.commit('setUserPermissions', c.data.userPermissions);
            store.commit('setUserName', user.userName);

            store.commit('setUserId', user.userId);
            store.commit('setCompany', c.data.company);
            store.commit('setAllowFutureInvoice', c.data.allowFutureInvoice);
            store.commit('setCompanyAllowFutureInvoice', c.data.companyAllowFutureInvoice)
            store.commit('setCompanyId', user.companyId);
           // store.commit('setLocations', c.data.locations);
            // store.commit('setPaymentTerms', c.data.paymentTerms)
           
        }).catch(function(){
            console.error(`Error while loading config...`);
        })
    }
    
    const persistSession = () =>{
        return localStorage.setItem('trp-login', JSON.stringify({
            jwt : store.state.session.jwt,
            sessionId: store.state.session.sessionId,
            permissions: store.state.session.permissions,
            refreshToken : store.state.session.refreshToken,
            role : store.state.session.role
        }));
    };

export default store;